<template>
    <div class="Awarp">
        <dashCard class="energykanbanBox6" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">当前工单总览</template>
            <template slot="aside">

            </template>
            <div class="card-content1">
                <div class="type-group">
                    <span class="pick-type" v-for="(item,idx) in timetType" :key="idx" @click="changeType(item.code)">
                        <font :class="{'isactive-type': item.code == searchObj.TYPE}">{{ item.name }}</font>
                    </span>
                </div>
                <a-date-picker v-if="searchObj.TYPE=='按天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"/>
                <a-week-picker v-if="searchObj.TYPE=='按周'" :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"  v-model="searchObj.VALUE" @change="onChange" />
                <a-month-picker v-if="searchObj.TYPE=='按月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="searchObj.VALUE" @change="onChange" />
                <a-date-picker v-if="searchObj.TYPE=='按年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"
                mode="year"
                :open="panelOpen"
                @openChange="openChange"
                @panelChange="panelChange"
                format="YYYY"
                />
            </div>
            <div class="card-content2">
                <div>今年总工单数量(个)</div>
                <div>1245</div>
                <div>
                    <i class="el-icon-caret-bottom"></i>
                    <font>11.2%</font>
                    <p>较上年</p>
                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <energykanbanBox6  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'energykanbanBox6',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                timetType: [
                    {
                        code: '按天',
                        name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
                    },
                    {
                        code: '按周',
                        name:'按周'
                    },
                    {
                        code: '按月',
                        name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
                    },
                    {
                        code: '按年',
                        name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
                    }
                ],
                panelOpen: false,
                searchObj: {
                    TYPE: '按年',//按天,按周,按月,按年
                    VALUE: this.$moment(),//日期
                },
                detailInfo: {

                },

            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

        // 改变类型
        changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {
                value1: 51276.33,
                value2: 11.2
            }
            this.isLoading = false
        },

            

        },
    
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.energykanbanBox6 {
    .card-content1 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .type-group {
            width: 160px;
        }
    }
    .card-content2 {
        flex: 1;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div{
            &:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 11px;
            }
            &:nth-child(2) {
                height: 92px;
                opacity: 0.7;
                width: 100%;
                background: rgba(240,245,255,.7);
                border-radius: 2px;
                text-align: center;
                line-height: 92px;
                font-size: 48px;
                font-weight: 400;
                color: rgba(29,33,41,1);
            }
            &:nth-child(3) {
                display: flex;
                margin-top: 9px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                i,font{
                    font-size: 13px;
                    color: rgb(245, 63, 63);
                    margin-right: 3px;
                }
                i {
                    line-height: 22px;
                    
                }
            }
        }
    }   
}
</style>